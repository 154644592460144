<template>
	<div class="dialog" style="background: white">
		<div class="dialog__header is-flex p-4">
			<h2>Comandă</h2>
			<button
				style="margin-left: auto"
				class="btn btn--close"
				@click="closeModal"
			>
				<ion-icon name="close-outline"></ion-icon>
			</button>
		</div>

		<div class="dialog__content">
			<div class="inner avp-dialog-inner">
				<!-- PRODUCTS LIST -->
				<ul v-if="products" class="products mb-4">
					<li
						class="product is-flex is-align-items-center p-2"
						v-for="product in products"
						:key="product.id"
					>
						<a
							:href="product._routePath"
							target="_blank"
							class="product__image is flex is-align-items-center is-justify-content-center"
						>
							<img
								:src="
									`${databaseLink}/${product.imagine.url}`
								"
								:alt="product.titlu"
							/>
						</a>
						<a
							:href="product._routePath"
							target="_blank"
							class="product__title px-3"
						>
							{{ product.titlu }}
						</a>
						<div class="product__price has-text-centered">
							{{ product._pret }} RON
						</div>
					</li>
					<li
						class="product is-flex is-align-items-center p-3"
					>
						<span class="product__total pr-3">
							<strong>TOTAL</strong>
						</span>
						
						<div class="product__price has-text-centered pr-3">
							{{ productsTotal }} RON
						</div>
					</li>
				</ul>
				<!-- PRODUCT -->
				<div
					v-if="product"
					class="product product__with-quantity is-flex no-border is-align-items-center mb-4 p-2"
				>
					<a
						:href="product._routePath"
						target="_blank"
						class="product__image is-flex is-justify-content-center"
					>
						<img
							:src="`${databaseLink}/${product.imagine.url}`"
							:alt="product.titlu"
						/>
					</a>
					<a
						:href="product._routePath"
						target="_blank"
						class="product__title px-3"
					>
						{{ product.titlu }}
					</a>
					<div class="product__quantity">
						<label for="quantity">Cantitate produs</label>
						<input
							type="number"
							id="quantity"
							name="quantity"
							min="1"
							v-model="product._cantitate"
							@change="updatePrice(product.id)"
						/>
					</div>
					<div class="product__price has-text-centered">
						{{ product._pret }} RON
					</div>
				</div>
				<!-- FORM -->
				<form ref="form" class="form">
					<div class="columns is-multiline">
						<div class="column is-5-desktop is-11-tablet">
							<div
								class="field"
								:class="{ 'field--error': $v.name.$error }"
							>
								<label for="name">Nume și prenume</label>
								<input
									type="text"
									id="name"
									name="name"
									v-model="name"
								/>
								<p
									class="error"
									v-if="
										!$v.name.required && $v.name.$dirty
									"
								>
									Câmpul este obligatoriu
								</p>
								<p
									class="error"
									v-if="!$v.name.alpha && $v.name.$dirty"
								>
									Câmpul trebuie să conțină doar caractere
								</p>
							</div>
						</div>
						<div class="column is-5-desktop is-11-tablet">
							<div
								class="field"
								:class="{ 'field--error': $v.phone.$error }"
							>
								<label for="phone">Telefon</label>
								<input
									type="text"
									id="phone"
									name="phone"
									v-model="phone"
								/>
								<p
									class="error"
									v-if="
										!$v.phone.required &&
											$v.phone.$dirty
									"
								>
									Câmpul este obligatoriu
								</p>
								<p
									class="error"
									v-if="
										(!$v.phone.minLength ||
											!$v.phone.maxLength) &&
											$v.phone.$dirty
									"
								>
									Câmpul trebuie să conțină
									{{ $v.phone.$params.maxLength.max }}
									numere
								</p>
							</div>
						</div>
						<div class="column is-5-desktop is-11-tablet">
							<div
								class="field"
								:class="{ 'field--error': $v.mail.$error }"
							>
								<label for="mail">Email</label>
								<input
									type="text"
									id="mail"
									v-model="mail"
								/>
								<p
									class="error"
									v-if="
										!$v.mail.required && $v.mail.$dirty
									"
								>
									Câmpul este obligatoriu
								</p>
								<p
									class="error"
									v-if="!$v.mail.email && $v.mail.$dirty"
								>
									Adresa de mail trebuie să fie validă
								</p>
							</div>
						</div>
						<div class="column is-5-desktop is-11-tablet">
							<div
								class="field"
								:class="{ 'field--error': $v.place.$error }"
							>
								<label for="place">Adresa</label>
								<textarea
									rows="2"
									type="textarea"
									id="place"
									v-model="place"
								></textarea>
								<p
									class="error"
									v-if="
										!$v.place.required &&
											$v.place.$dirty
									"
								>
									Câmpul este obligatoriu
								</p>
							</div>
						</div>
						<!-- <div class="column is-5-desktop is-11-tablet">
							<div class="field">
								<label for="paymentOptions">Opțiune plată</label>
								<select v-model="selectedPaymentOption" id="paymentOptions">
									<option
										v-for="(option, index) in paymentOptions"
										:key="index"
										:value="option"
										>{{ option == 'numerar' && 'Plata numerar la livrare' || 'Plata online prin foreverliving.ro' }}</option
									>
								</select>
							</div>
						</div> -->
						<div class="column is-11">
							<div class="notification is-warning" v-if="selectedPaymentOption == 'online'">
								Pentru a finaliza comanda veți fi redirecționat către site-ul oficial foreverliving.ro. Acceptând să faceți cumpărăturile folosind acest link veti beneficia de suport gratuit permanent din partea unui consultant cu vastă experiență la care puteți apela cu orice întrebare.
								<br>
								<strong>Meșter Marin Aurel – diamant manager</strong>
								<br>
								<a href="tel:+40744775567">
									<ion-icon name="call-outline"></ion-icon>
									+40 744 775 567
								</a>
								<br>
								<a href="mailto:contact@aloeveraproducts.ro">
									<ion-icon name="mail-outline"></ion-icon>
									contact@aloeveraproducts.ro
								</a>
							</div>
						</div>
					</div>
					<div class="field mb-0">
						<label
							for="terms"
							class="no-borders no-bg is-flex is-align-items-center check"
						>
							<div class="checkbox is-relative mr-3">
								<input
									type="checkbox"
									id="terms"
									v-model="terms"
								/>
								<span class="checkbox__box"
									><ion-icon
										name="checkmark-outline"
									></ion-icon
								></span>
							</div>
							<p>
								Sunt de acord cu prelucrarea datelor cu
								caracter personal conform Regulamentului
								nr.679/2016 și cu
								<a
									href="https://aloeveraproducts.ro/termeni-conditii.pdf"
									target="_blank"
									>politica de confidențialitate</a
								>
							</p>
						</label>
						<p
							class="error"
							v-if="!$v.terms.required && $v.terms.$dirty"
						>
							Câmpul este obligatoriu
						</p>
					</div>
				</form>
			</div>
			
		</div>
		<div class="dialog__footer is-flex is-justify-content-flex-end p-4">
			<button
				class="btn btn--secondary mr-2"
				title="Înapoi la produse"
				aria-label="Înapoi la produse"
				@click="closeModal"
			>
				Înapoi la coș
			</button>
			<button
				:disabled="$v.$anyError"
				@click.prevent="submit"
				class="btn btn--primary btn--purchase"
			>
				Trimitere comandă
			</button>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import {
		required,
		minLength,
		maxLength,
		numeric,
		email,
	} from 'vuelidate/lib/validators';
	import { helpers } from 'vuelidate/lib/validators';
	const alpha = helpers.regex('alpha', /^([a-zA-Z]+\s)*[a-zA-Z]+$/);

	export default {
		props: ['product', 'products', 'typeModal'],
		validations: {
			name: {
				required,
				alpha,
			},
			phone: {
				required,
				numeric,
				minLength: minLength(10),
				maxLength: maxLength(10),
			},
			mail: {
				required,
				email,
			},
			place: {
				required,
			},
			terms: {
				required,
			},
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			productsTotal() {
				const total = this.products.reduce((sum, product) => sum + parseFloat(product._pret), 0);
				return parseFloat(total).toFixed(2);
			}
		},
		data() {
			return {
				// fullPrice: null,
				// disccountAndConsulting: null,
				// disccount: null,
				// consultant: null,
				name: '',
				phone: null,
				mail: '',
				place: '',
				paymentOptions: [
					'numerar',
					'online',
				],
				selectedPaymentOption: 'online',
				terms: null,
			};
		},
		created() {
			// this.selectedPaymentOption = this.paymentOptions[0];
		},
		methods: {
			updatePrice(id) {
				this.$store.commit('product/UPDATE_QUANTITY_PRICE', {
					id,
					type: 'product',
				});
			},
			closeModal() {
				if (this.product) {
					this.$store.commit(
						'product/SET_MODAL_COMMAND_WITH_SINGLE_PRODUCT_STATUS',
						{ product: null, status: false }
					);
				} else {
					this.$store.commit(
						'product/SET_MODAL_COMMAND_WITH_MULTIPLE_PRODUCTS_STATUS',
						false
					);
				}
				// this.fullPrice = null;
				// this.disccountAndConsulting = null;
				// this.disccount = null;
				// this.consultant = null;
				this.name = '';
				this.mail = '';
				this.phone = null;
				this.place = '';
				this.selectedPaymentOption = 'online';
				this.terms = null;
				this.$v.$reset();
				this.$emit('close');
			},
			submit() {
				var self = this;
				this.$v.$touch();
				if (this.$v.$invalid) {
					return;
				} else {
					let products = [];
					if (this.products) {
						products = this.products.map((product) => {
							return {
								titlu: product.titlu,
								imagine: product.imagine.id,
								cantitate: product._cantitate,
								pret: product._pret,
							};
						});
					} else if (this.product) {
						products.push({
							titlu: this.product.titlu,
							imagine: this.product.imagine.id,
							cantitate: this.product._cantitate,
							pret: this.product._pret,
						});
					}
					axios
						.post(
							`${process.env.VUE_APP_STRAPI_DATABASE_URL}/orders`,
							{
								nume: this.name,
								telefon: this.phone,
								mail: this.mail,
								adresa: this.place,
								plata: this.selectedPaymentOption,
								politica_de_confidentialitate: this.terms,
								produse: products,
							}
						)
						.then((data) => {
							// Create the click event in google analytics when the form is submitted and the response is 200
							if (data.status === 200) {
								if(window.dataLayer) {
									window.dataLayer.push({
										event: 'click_purchase_product',
									});
								}
							}

							try {
								// if(self.selectedPaymentOption != 'online') {
									axios.post(
										`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailo`,
										{ ...data }
									);
								// }
								
								axios.post(
									`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailod`,
									{ ...data }
								);
							}
							catch(err) {
								console.log('Probleme la trimitere emailuri.', err);
							}

							try {
								if(self.selectedPaymentOption == 'online') {
									const urlToOpen = 'https://foreverliving.ro/?rid=400-000-000-610';
									window.open(urlToOpen, '_blank');

									this.$store.commit(
										'general/SET_FORM_MSG_AFTER_SUBMITED',
										{
											status: 'submited',
											msg:
												'Mulțumim pentru interes. Accesați acest <a href="'+urlToOpen+'" target="_blank">LINK</a> pentru a comanda online.',
										}
									);
									
								}
								else {
									this.$store.commit(
										'general/SET_FORM_MSG_AFTER_SUBMITED',
										{
											status: 'submited',
											msg:
												'Mulțumim pentru interes. Am preluat comanda dvs. și vă vom contacta în cel mai scurt timp pentru confirmare.',
										}
									);
								}
								
								this.closeModal();
								this.$store.commit(
									'product/SET_MODAL_PRODUCTS_LIST_STATUS',
									false
								);
								this.$store.commit('product/CLEAR_LIST');
							}
							catch (err) {
								console.log('Erori la procesare comandă.', err);
							}
							
						})
						.catch((err) => {
							console.log('Eroare generică la procesare comandă.', err);
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'error',
									msg:
										'Am întâmpinat o eroare. Comanda ta nu a fost trimisă.',
								}
							);
							this.closeModal();
							this.$store.commit(
								'product/SET_MODAL_COMMAND_WITH_MULTIPLE_PRODUCTS_STATUS',
								false
							);
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.product {
		background-color: $white;
		border-radius: $round-corners;
		margin-bottom: 0.5rem;
		&:last-child {
			margin: 0;
		}
		&__image {
			text-align: center;
			width: 60px;
			img {
				width: 60px;
				height: auto;
			}
		}
		&__title {
			font-size: 1rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: calc(100% - (60px + 150px));
		}
		&__total {
			font-size: 1rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: calc(100% - 50px);
		}
		&__quantity {
			label {
				position: absolute;
				left: -9999px;
			}
			input {
				background: none;
				box-sizing: border-box;
				border: 1px solid $gray;
				border-radius: $round-corners;
				color: $primary;
				display: inline-block;
				font-size: 1rem;
				padding: 0.5rem;
				text-align: center;
				transition: border-color 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out;
				width: 80px;
				&:focus {
					box-shadow: 0 0 0 3px rgba($primary, 0.25);
					border-color: $primary;
					outline: 0;
				}
			}
		}
		&__price {
			color: $primary;
			font-weight: $semibold;
			width: 150px;
		}
		&__with-quantity {
			.product__title {
				width: calc(100% - (60px + 80px + 150px));
			}
		}
		@media (max-width: 550px) {
			flex-direction: column;
			.product {
				&__image {
					width: 100px;
					padding: 0 !important;
					img {
						width: 100%;
					}
				}
				&__title {
					width: auto;
					padding: 0 !important;
					margin-bottom: 0.5rem;
				}
				&__price {
					padding: 0 !important;
					margin: 0.5rem 0;
				}
			}
		}
	}

	.avp-dialog-inner {
		overflow: auto;
		height: calc(100vh - 200px);	
	}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product has-text-centered is-flex is-flex-direction-column"},[(_vm.fromPage === 'home')?_c('router-link',{staticClass:"product__image is-block",attrs:{"to":`/produse/produs/${_vm.formatProductTitle(_vm.product.titlu)}-${
				_vm.product.id
			}/`}},[_c('img',{attrs:{"height":"400","src":`${_vm.databaseLink}/${_vm.product.imagine.url}`,"alt":_vm.product.titlu}})]):_c('router-link',{staticClass:"product__image is-block",attrs:{"to":`${_vm.$route.path}produse/${_vm.formatProductTitle(_vm.product.titlu)}-${
				_vm.product.id
			}/`}},[_c('img',{attrs:{"height":"400","src":`${_vm.databaseLink}/${_vm.product.imagine.url}`,"alt":_vm.product.titlu}})]),_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center p-3",staticStyle:{"height":"100%"}},[(_vm.fromPage === 'home')?_c('router-link',{staticClass:"product__title is-block mb-4",attrs:{"to":`/produse/produs/${_vm.formatProductTitle(_vm.product.titlu)}-${
					_vm.product.id
				}/`}},[_vm._v(" "+_vm._s(_vm.product.titlu)+" ")]):_c('router-link',{staticClass:"product__title is-block mb-4",attrs:{"to":`${_vm.$route.path}produse/${_vm.formatProductTitle(
					_vm.product.titlu
				)}-${_vm.product.id}/`}},[_vm._v(" "+_vm._s(_vm.product.titlu)+" ")]),(_vm.product.pret && _vm.product.pret > 0)?_c('strong',{staticClass:"product__price is-block mb-4"},[_vm._v(" "+_vm._s(_vm.product.pret)+" RON ")]):_vm._e(),(_vm.fromPage === 'home')?_c('router-link',{staticClass:"btn btn--primary",attrs:{"to":`/produse/produs/${_vm.formatProductTitle(_vm.product.titlu)}-${
					_vm.product.id
				}/`}},[_vm._v("Detalii produs")]):_c('router-link',{staticClass:"btn btn--primary",attrs:{"to":`${_vm.$route.path}produse/${_vm.formatProductTitle(
					_vm.product.titlu
				)}-${_vm.product.id}/`}},[_vm._v("Detalii produs")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
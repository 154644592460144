<template>
	<div id="app" data-server-rendered="true">
		<div
			class="overlay"
			:class="{ 'is-open': menuIsOpen }"
			@click.self="closeMenuOnOverlay"
		></div>
		<menu-component
			@open-menu="openMenu"
			@close-menu="closeMenuOnButton"
			:is-closed="menuIsOpen"
		/>
		<template v-if="$route.path === '/'">
			<banner-carousel
				:slides="homeBannerCarouselSlides"
			></banner-carousel>
			<!-- <component-audio v-if="audio && audio.length > 0" :audio="audio" /> -->
			<!-- <motto-component v-if="homeMotto[0]" style="background: #f7f7f7;">
				<h2 class="has-text-centered">{{ homeMotto[0].titlu }}</h2>
			</motto-component> -->

			<section class="section section--larger">
				<div class="container">
					<div class="columns is-multiline is-centered">
						<component-category
							v-for="category in categories"
							:key="category.id"
							:category="category"
						/>
					</div>
				</div>
			</section>

			<section v-if="$route.path === '/'" class="section section__catalog section--larger">
				<div class="container">
					<h2 class="section__title has-text-centered">
						Client preferențial (reducere min. 5%)
					</h2>
					<div class="columns is-multiline is-centered">
						<button @click="openModalPreferredCustomer" class="button is-medium is-info mr-2 mb-2">
							CREARE CONT CLIENT PREFERENȚIAL
							<div class="icon ml-2">
								<ion-icon name="person-circle-outline"></ion-icon>
							</div>
						</button> 
					</div>
				</div>
			</section>
		</template>

		<!-- <section v-if="$route.path === '/'" class="section section__catalog section--larger">
			<div class="container">
				<h2 class="section__title has-text-centered">
					Descarcă gratuit
				</h2>
				<div class="columns is-multiline is-centered">
					<a href="https://aloeveraproducts.ro/CATALOG_PRODUSE_FOREVER.pdf" target="_blank" titl="CATALOG PRODUSE FOREVER 2023">
						<button class="button is-medium is-info mr-2 mb-2">
							<div class="icon mr-2">
								<ion-icon name="book-outline"></ion-icon>
							</div>
							CATALOG PRODUSE FOREVER 2023
							<div class="icon mr-2">
								<ion-icon name="cloud-download-outline"></ion-icon>
							</div>
						</button> 
					</a>
					<a href="https://aloeveraproducts.ro/LISTA_DE_PRETURI.pdf" target="_blank" title="CATALOG PRODUSE FOREVER 2023">
						<button class="button is-medium is-info mb-2">
							<div class="icon mr-2">
								<ion-icon name="list-circle-outline"></ion-icon>
							</div>
							LISTA DE PREȚURI
							<div class="icon mr-2">
								<ion-icon name="cloud-download-outline"></ion-icon>
							</div>
						</button>
					</a>
				</div>
			</div>
		</section> -->

		<contact-links />
		<transition v-if="loading" name="fade">
			<component-loading />
		</transition>
		<router-view v-else :key="$route.path" />
		
		<template v-if="$route.path === '/'">
			<section
				v-if="products && products.length > 0"
				class="section section__products section--larger"
			>
				<div class="container">
					<h2 class="section__title has-text-centered">
						Trebuie să le încerci
					</h2>
					<div class="columns is-multiline is-centered">
						<div
							class="column is-4-tablet is-3-desktop mb-5"
							v-for="product in highlightedProducts"
							:key="product.id"
						>
							<component-product :product="product" fromPage="home" />
						</div>
					</div>
				</div>
			</section>
			<!-- <section
				v-if="articles && articles.length > 0"
				class="section section__articles section--larger"
			>
				<div class="container">
					<h2 class="section__title has-text-centered">
						Articole evidențiate
					</h2>
					<div class="columns is-multiline is-centered">
						<div
							class="column is-6-tablet is-4-desktop mb-5"
							v-for="article in highlightedArticles"
							:key="article.id"
						>
							<component-article
								:article="article" fromPage="home"
							></component-article>
						</div>
					</div>
				</div>
			</section> -->
		</template>

		<!-- <component-certificates
			:bgGrey="true"
			v-if="$route.path === '/'"
			:certificates="certificates"
		></component-certificates> -->
		<motto-component v-if="$route.path === '/' && homeMotto[1]">
			<h2 class="has-text-centered" v-html="homeMotto[1].titlu"></h2>
		</motto-component>
		<!-- <testimonial-carousel v-if="$route.path === '/'" /> -->

		<!-- <template v-if="contentOnlyOnCategoriesPage || $route.path === '/'">
			<owner-carousel />
		</template> -->
		<footer-component />

		<div class="home-page">
			<b-modal
				v-model="modalPreferredCustomerSetterAndGetter"
				trap-focus
				:destroy-on-hide="false"
				aria-role="dialog"
				aria-modal
			>
				<template #default="props">
					<preferred-customer @close="props.close"></preferred-customer>
				</template>
			</b-modal>
		</div>

		<div class="product-page">
			<!-- MODAL WITH LIST OF PRODUCTS -->
			<b-modal
				v-model="modalProductsListSetterAndGetter"
				trap-focus
				:destroy-on-hide="false"
				aria-role="dialog"
				aria-modal
			>
				<template #default="props">
					<products-list
						:products="productsList"
						@close="props.close"
					></products-list>
				</template>
			</b-modal>

			<!-- COMMAND MODAL WITH MULTIPLE PRODUCTS  -->
			<b-modal
				v-model="modalWithMultipleProductsSetterAndGetter"
				trap-focus
				:destroy-on-hide="false"
				aria-role="dialog"
				aria-modal
			>
				<template #default="props">
					<component-command-modal
						@close="props.close"
						:products="productsList"
					></component-command-modal>
				</template>
			</b-modal>
		</div>

		<transition name="slide">
			<component-submited-form
				v-if="submitedForm"
				:submitedForm="submitedForm"
			/>
		</transition>
		<component-cookie />
	</div>
</template>
<script>
	import MenuComponent from '@/components/TheMenu.vue';
	import FooterComponent from '@/components/TheFooter.vue';
	import BannerCarousel from '@/components/TheBannerCarousel.vue';
	import MottoComponent from '@/components/TheMotto.vue';
	import ComponentCategory from '@/components/ComponentCategory.vue';
	import ContactLinks from '@/components/TheContactLinks.vue';
	// import OwnerCarousel from '@/components/TheOwnerCarousel.vue';
	// import TestimonialCarousel from '@/components/TheTestimonialCarousel.vue';
	// import ComponentCertificates from '@/components/ComponentCertificates.vue';
	import ProductsList from '@/components/ComponentProductsList.vue';
	import ComponentCommandModal from '@/components/ComponentCommandModal.vue';
	import ComponentProduct from '@/components/ComponentProduct.vue';
	// import ComponentArticle from '@/components/ComponentArticle.vue';
	import ComponentSubmitedForm from '@/components/ComponentSubmitedForm.vue';
	// import ComponentAudio from '@/components/ComponentAudio.vue';
	import ComponentLoading from '@/components/ComponentLoading.vue';
	import ComponentCookie from '@/components/ComponentCookie.vue';
	import PreferredCustomer from '@/components/ComponentPreferredCustomer.vue';

	import { mapState, mapActions } from 'vuex';
	export default {
		title: 'Aloe Vera Products',
		metaInfo() {
			return {
				title: `Aloe Vera Products`
			};
		},
		components: {
			MenuComponent,
			FooterComponent,
			BannerCarousel,
			MottoComponent,
			ComponentCategory,
			ContactLinks,
			// OwnerCarousel,
			// TestimonialCarousel,
			// ComponentCertificates,
			ProductsList,
			ComponentCommandModal,
			ComponentProduct,
			// ComponentArticle,
			ComponentSubmitedForm,
			// ComponentAudio,
			ComponentLoading,
			ComponentCookie,
			PreferredCustomer
		},
		data() {
			return {
				menuIsOpen: false,
			};
		},
		beforeCreate() {
			// fetch(
			// 	'https://api.geoapify.com/v1/ipinfo?&apiKey=25f34a73450b4898a323b4750bc290c0'
			// )
			// 	.then((response) => response.json())
			// 	.then((result) => {
			// 		localStorage.setItem('location', result.country.iso_code);
			// 	});

			localStorage.setItem('location', 'RO');
		},
		created() {
			this.getGeneralData();
		},
		computed: {
			...mapState('general', [
				'homeMotto',
				'categories',
				'certificates',
				'homeBannerCarouselSlides',
				'products',
				'articles',
				'audio',
				'audioStatus',
				'submitedForm',
				'loading',
				'modalPreferredCustomer'
			]),
			...mapState('product', [
				'modalProductsList',
				'modalWithMultipleProducts',
				'productsList'
			]),
			highlightedProducts() {
				// const sortedProducts = this.sortProductsByCategoryAndPosition();
				// const highlightedFilteredProducts = this.filterFirstHighlightedProduct(sortedProducts);
				
				let highlightedFilteredProducts = this.products.filter((p) => {
					return p.highlight_produs;
				});
				
				let highlightedFilteredAndOrderedByPositionProducts = highlightedFilteredProducts.sort((a, b) => a.pozitie - b.pozitie);

				// console.log('highlightedFilteredAndOrderedByPositionProducts', highlightedFilteredAndOrderedByPositionProducts); 

				return highlightedFilteredAndOrderedByPositionProducts.slice(0, 12);
				// return this.products;
			},
			highlightedArticles() {
				return this.articles.filter((art) => {
					return art.highlight_articol === true;
				});
			},
			contentOnlyOnCategoriesPage() {
				return (
					this.$route.path === '/aloe-vera' ||
					this.$route.path === '/igiena-personala' ||
					this.$route.path === '/sanatate' ||
					this.$route.path === '/dezvoltare-personala' ||
					this.$route.path === '/frumusete' ||
					this.$route.path === '/detoxifiere-controlul-greutatii' ||
					this.$route.path === '/nutritie-avansata-si-sport' ||
					this.$route.path === '/business-alatura-te-echipei'
				);
			},
			modalProductsListSetterAndGetter: {
				set() {
					return this.$store.commit(
						'product/SET_MODAL_PRODUCTS_LIST_STATUS',
						false
					);
				},
				get() {
					return this.modalProductsList;
				},
			},
			modalWithMultipleProductsSetterAndGetter: {
				set() {
					return this.$store.commit(
						'product/SET_MODAL_COMMAND_WITH_MULTIPLE_PRODUCTS_STATUS',
						false
					);
				},
				get() {
					return this.modalWithMultipleProducts;
				},
			},
			modalPreferredCustomerSetterAndGetter: {
				set() {
					return this.$store.commit(
						'general/SET_MODAL_PREFERRED_CUSTOMER_STATUS',
						false
					);
				},
				get() {
					return this.modalPreferredCustomer;
				},
			},
		},
		methods: {
			...mapActions('general', ['getGeneralData']),
			openMenu(value) {
				this.menuIsOpen = value;
			},
			closeMenuOnOverlay() {
				this.menuIsOpen = false;
			},
			closeMenuOnButton(value) {
				this.menuIsOpen = value;
			},
			sortProductsByCategoryAndPosition() {
				return this.products.slice().sort((prodA, prodB) => {
					const categoryAId = prodA.categories.length > 0 && prodA.categories[0] && prodA.categories[0].id ? prodA.categories[0].id : 0;
					const categoryBId = prodB.categories.length > 0 && prodB.categories[0] && prodB.categories[0].id ? prodB.categories[0].id : 0;

					if (categoryAId !== categoryBId) {
						return categoryAId - categoryBId;
					}

					return prodA.pozitie - prodB.pozitie;
				});
			},

			filterFirstHighlightedProduct(sortedProducts) {
				const visitedCategories = new Set();
				const highlightedFilteredProducts = [];

				for (const prod of sortedProducts) {
					const categoryId = prod.categories.length > 0 && prod.categories[0] && prod.categories[0].id ? prod.categories[0].id : 0;
					
					if (!visitedCategories.has(categoryId)) {
						visitedCategories.add(categoryId);

						if (prod.highlight_produs) {
							highlightedFilteredProducts.push(prod);
						}
					}
				}

				return highlightedFilteredProducts;
			},

			openModalPreferredCustomer() {
				console.log('openModalPreferredCustomer');
				this.$store.commit(
					'general/SET_MODAL_PREFERRED_CUSTOMER_STATUS',
					true
				);
			},
		},
		watch: {
			$route() {
				this.menuIsOpen = false;
			},
		},
	};
</script>

<style lang="scss">
	.fb-customerchat iframe {
		bottom: 10%;
		z-index: 8;
	}
	.home-page .modal-content,
	.product-page .modal-content,
	.page-business-alatura-te-echipei .modal-content {
		width: 100%;
	}
	// Menu
	.overlay {
		background-color: rgba($black, 0.85);
		opacity: 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		transition: opacity 0.3s ease;
		pointer-events: none;
		z-index: 3;
		&.is-open {
			opacity: 1;
			pointer-events: all;
		}
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	/*----------  GENERAL  ----------*/
	.modal-close {
		display: none;
	}
	.dialog {
		border-radius: $round-corners;
		.vuebar {
			height: 500px;
		}
	}
	.vuebar {
		height: 250px;
		width: 100%;
	}

	.vb > .vb-dragger {
		z-index: 5;
		width: 10px;
		right: 0;
	}

	.vb > .vb-dragger > .vb-dragger-styler {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-transform: rotate3d(0, 0, 0, 0);
		transform: rotate3d(0, 0, 0, 0);
		-webkit-transition: background-color 100ms ease-out,
			margin 100ms ease-out, height 100ms ease-out;
		transition: background-color 100ms ease-out, margin 100ms ease-out,
			height 100ms ease-out;
		background-color: rgba($primary, 0.4);
		margin: 0;
		border-radius: $round-corners;
		height: 100%;
		display: block;
	}

	.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
		background-color: rgba($primary, 0.4);
	}

	.vb > .vb-dragger:hover > .vb-dragger-styler {
		background-color: rgba($primary, 0.8);
		margin: 0px;
		height: 100%;
	}

	.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
		background-color: rgba($primary, 0.8);
		margin: 0px;
		height: 100%;
	}

	.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
		background-color: rgba($primary, 0.8);
	}
	.modal-content {
		overflow: initial;
		max-height: initial;
	}
	.page-aloe-vera {
		em {
			display: inline-block;
			padding: 0.5rem;
		}
	}
	/*----------  MARKDOWN  ----------*/
	.footer__bottom {
		.markdown-body {
			p,
			strong {
				color: darken($gray, 10%);
				font-size: 1rem;
			}
			a {
				color: darken($gray, 5%) !important;
				&:hover {
					color: darken($gray, 20%) !important;
				}
			}
		}
	}
	.markdown-body {
		.anchor {
			display: none !important;
		}
		h2 {
			padding: 0 !important;
			border: 0 !important;
		}
		strong {
			color: $black;
		}
		a {
			color: $primary !important;
			text-decoration: none !important;
			&:hover {
				color: $secondary !important;
			}
		}
		ul,
		ol {
			padding: 0 !important;
		}
		ul li {
			list-style-type: disc !important;
		}
	}

	.page-category .markdown-body h2 {
		text-align: center;
		text-transform: uppercase;
		font-size: 1.5rem;
		font-weight: $bold;
	}
	.page-category .markdown-body p {
		font-size: 1.125rem;
	}

	/*----------  HOME PAGE  ----------*/
	.swiper-pagination {
		bottom: 0 !important;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20px;
	}
	.testimonial {
		padding-bottom: 20px;
		.swiper-container-horizontal > .swiper-pagination-bullets {
			bottom: 0;
		}
		.swiper-pagination-bullet {
			background-color: transparent;
			border: 2px solid $secondary;
			opacity: 1;
			width: 0.875rem;
			height: 0.875rem;
		}
		.swiper-pagination-bullet-active {
			background-color: $secondary;
		}
	}
	/*----------  BUSINESS PAGE  ----------*/
	.business-page {
		.banner {
			strong {
				display: block;
			}
		}
	}

	.slide-enter-active,
	.slide-leave-active {
		transition: opacity 0.5s;
	}
	.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>

<template>
	<ul class="contact-list">
		<li
			@click="openModalProductsList"
			v-if="productsList && productsList.length > 0"
			class="item is-relative"
			title="Lista de produse"
			aria-label="Vizualizează lista de produse"
		>
			<span class="products-number">
				{{ productsList.length }}
			</span>
			<div class="item__icon is-relative">
				<ion-icon name="cart-outline"></ion-icon>
			</div>
		</li>

		<br>

		<li class="item is-relative">
			<div class="item__content p-0 is-flex">
				<a
					@click="changeLanguage('RO')"
					class="is-flex-grow-1 is-relative has-text-white"
					href="javascript:void(0)"
					>RO</a
				>
				<a
					@click="changeLanguage('EN')"
					class="is-flex-grow-1 has-text-white"
					href="javascript:void(0)"
					>EN</a
				>
			</div>
			<div ref="dispayedLang" class="item__icon is-relative">
				RO
			</div>
		</li>
		<li
			:title="
				info.simbol === 'call-outline' ? 'Telefon' : 'Adresa de mail'
			"
			:aria-label="
				info.simbol === 'call-outline'
					? 'Contactează-ne pe telefon'
					: 'Contactează-ne pe adresa de mail'
			"
			class="item is-relative"
			v-for="info in sideContactInfos"
			:key="info.id"
		>
			<a class="item__content" :href="info.url">{{ info.titlu }}</a>
			<div class="item__icon is-relative">
				<ion-icon :name="info.simbol"></ion-icon>
			</div>
		</li>
	</ul>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	export default {
		data() {
			return {};
		},
		beforeCreate() {
			this.lang = localStorage.getItem('lang');
			this.location = localStorage.getItem('location');
		},
		computed: {
			...mapState('general', ['contactInfos']),
			...mapState('product', ['productsList']),
			sideContactInfos() {
				return this.contactInfos.filter((ci) => {
					return ci.simbol == 'call-outline' || ci.simbol == 'mail-outline'
				});
			}
		},
		methods: {
			...mapActions('general', ['getGeneralData']),
			changeLanguage(lang) {
				localStorage.setItem('lang', lang);
				const loc = localStorage.getItem('location');
				if (
					(loc === 'RO' && loc === 'RO' && lang === null) ||
					(loc !== 'RO' && lang === null) ||
					(loc === 'RO' && lang !== null && lang === 'RO') ||
					(loc !== 'RO' && lang !== null && lang === 'RO')
				) {
					this.$refs.dispayedLang.textContent = 'RO';
				} else if (
					(loc !== 'RO' && loc !== 'RO' && lang === null) ||
					(loc === 'RO' && lang !== null && lang === 'EN') ||
					(loc !== 'RO' && lang !== null && lang === 'RO')
				) {
					this.$refs.dispayedLang.textContent = 'EN';
				}
				this.getGeneralData();
			},
			openModalProductsList() {
				this.$store.commit(
					'product/SET_MODAL_PRODUCTS_LIST_STATUS',
					true
				);
			},
		},
		mounted() {
			if (
				(this.location === 'RO' &&
					(this.lang === '' || this.lang === 'RO')) ||
				(this.location !== 'RO' && this.lang === 'RO')
			) {
				this.$refs.dispayedLang.textContent = 'RO';
			} else {
				this.$refs.dispayedLang.textContent = 'EN';
			}
		},
	};
</script>

<style scoped lang="scss">
	.contact-list {
		position: fixed;
		top: 40%;
		right: 0;
		z-index: 8;
		.item {
			width: 50px;
			margin-bottom: 1px;
			&:first-child {
				.item__icon {
					font-size: 1.125rem;
				}
				a {
					color: $white;
					text-align: center;
					&:hover {
						background-color: $primary;
					}
					&:first-child:after {
						content: '';
						display: block;
						width: 1px;
						height: 100%;
						position: absolute;
						right: -0.5px;
						top: 0;
						background: $primary;
					}
				}
			}
			&:last-child {
				margin: 0;
			}
			&:hover {
				.item__content {
					transform: translateX(calc(0px - 49px));
				}
			}
			&__icon {
				background-color: $primary;
				color: $white;
				font-size: 1.5rem;
				line-height: 56px;
				transition: background-color 0.3s ease;
				text-align: center;
				pointer-events: none;
				width: 50px;
				height: 50px;
				z-index: 1;
				&:hover {
					background-color: lighten($primary, 10%);
				}
			}
			&__content {
				background-color: lighten($primary, 10%);
				color: $white;
				display: block;
				font-weight: $medium;
				letter-spacing: 1px;
				line-height: 50px;
				padding: 0 0.5rem;
				position: absolute;
				top: 0;
				right: 0;
				transform: translateX(100%);
				transition: transform 0.3s ease-in-out;
				text-align: center;
				width: 250px;
				height: 50px;
			}
			.products-number {
				box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
				background-color: $secondary;
				border-radius: 50%;
				color: $white;
				font-size: 0.75rem;
				font-weight: $medium;
				line-height: 25px;
				position: absolute;
				top: 0;
				left: 0;
				transform: translateX(-50%);
				z-index: 3;
				text-align: center;
				width: 25px;
				height: 25px;
			}
		}
	}
</style>

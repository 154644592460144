var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fbo)?_c('nav',{staticClass:"navbar is-relative is-block"},[_c('div',{staticClass:"navbar__top",style:({
			// backgroundImage:
			// 	'url(' + require('@/assets/header-bg.png') + ')',
			backgroundColor: '#071D49'
		})},[_c('section',{staticClass:"section py-2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered forever__info mb-0"},[_c('div',{staticClass:"column-12 mt-2"},[_c('h6',[_vm._v(_vm._s(_vm.fbo.brand)+" "+_vm._s(_vm.fbo.brand_titlu)+" | ID: "+_vm._s(_vm.fbo.fboid))])])]),_c('div',{staticClass:"forever is-flex is-justify-content-space-between is-align-items-center"},[_c('router-link',{staticClass:"forever__logo",attrs:{"to":"/"}},[_c('img',{attrs:{"height":"60","src":`${_vm.databaseLink}/${_vm.fbo.logo.url}`,"alt":_vm.fbo.logo.alternativeText}})]),_c('div',{staticClass:"forever__owner is-inline-flex is-align-items-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('span',{staticClass:"is-size-3"},[_vm._v("ALOE VERA PRODUCTS")])])],1)],1)])])]),_c('div',{staticClass:"navbar__bottom is-relative"},[_c('section',{staticClass:"section py-0"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"forever is-flex is-align-items-center is-justify-content-space-between"},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('router-link',{staticClass:"avatar mr-3",attrs:{"to":"/"}},[_c('h2',{staticStyle:{"font-weight":"bold"}},[_vm._v("ALOE VERA PRODUCTS")])])],1),_c('div',{staticClass:"menu is-flex is-flex-direction-column is-justify-content-space-between",class:{ 'is-open': _vm.isClosed }},[_c('div',{staticClass:"menu__info pt-5 pb-3 has-text-centered is-relative",staticStyle:{"height":"147px"}},[_c('h6',[_vm._v(_vm._s(_vm.fbo.brand))]),_c('h6',[_vm._v(_vm._s(_vm.fbo.brand_titlu))]),_c('h6',[_vm._v("ID: "+_vm._s(_vm.fbo.fboid))]),_c('button',{staticClass:"btn btn--close",on:{"click":_vm.closeMenu}},[_c('ion-icon',{attrs:{"name":"close-sharp"}})],1)]),_c('ul',{staticClass:"menu__inner",style:([
								_vm.windowWidth < 991
									? { height: `calc(100% - 262px)` }
									: { height: 'auto' },
							])},_vm._l((_vm.headerLinks),function(link){return _c('li',{key:link.id},[_c('router-link',{attrs:{"to":`${link.url}/`}},[_vm._v(_vm._s(link.titlu))])],1)}),0),_c('router-link',{staticClass:"menu__logo py-3 is-block",attrs:{"to":"/"}},[_c('img',{attrs:{"src":`${_vm.databaseLink}/${_vm.fbo.logo.url}`,"alt":_vm.fbo.logo.alternativeText}})])],1),_c('button',{staticClass:"btn btn--toggle is-relative is-clickable",on:{"click":_vm.openMenu}},[_c('span',{staticClass:"inner"})])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
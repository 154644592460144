<template>
	<swiper :options="swiperOptions" class="primary-banner">
		<swiper-slide v-for="slide in slides" :key="slide.id">
			<!-- <router-link :to="slide.url"> -->
			<div class="slide__image is-relative">
				<img
					:src="
						width > 1000
							? `${databaseLink}/${slide.imagine[0].url}`
							: width <= 1000 && width > 750
							? `${databaseLink}/${slide.imagine[0].formats.large.url}`
							: width <= 750 && width > 500
							? `${databaseLink}/${slide.imagine[0].formats.medium.url}`
							: width <= 500
							? `${databaseLink}/${slide.imagine[0].formats.small.url}`
							: null
					"
					alt=""
				/>
				<!-- Add the action button here -->
				<!-- <a href="https://diamondteam.ro" class="action-button">VEZI DETALII</a> -->
				<!-- Or if you are using vue-router -->
				<!-- <router-link v-if="slide.url != ''" :to="slide.url" class="action-button">VEZI DETALII</router-link> -->
			</div>
			<!-- </router-link> -->
		</swiper-slide>
		<div class="swiper-button-prev" slot="button-prev"></div>
		<div class="swiper-button-next" slot="button-next"></div>
	</swiper>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		props: {
			slides: Array,
			type: String,
		},
		data() {
			return {
				width: window.innerWidth,
				swiperOptions: {
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					effect: 'fade',
					slidesPerView: 1,
					grabCursor: true,
					loop: true,
					autoplay: {
						delay: 4000,
					},
					speed: 400,
					keyboard: {
						enabled: true,
					},
					// autoHeight: true
				},
			};
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapState('general', ['homeBannerCarouselSlides']),
		},
		methods: {
			goToUrl(url) {
				if(url != '') {
					this.$router.push(url);
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	.swiper {
		width: 100%;
		height: auto;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
	}
	
	// .primary-banner {
	// 	background: $primary;
	// 	.slide {
	// 		&__image {
	// 			padding-top: 56.25%;
	// 			overflow: hidden;
	// 			img {
	// 				position: absolute;
	// 				left: 0;
	// 				top: 0;
	// 				width: 100%;
	// 				height: auto;
	// 			}
	// 		}
	// 	}
	// }
	// .swiper-container {
	// 	max-height: 379px; /* change this value to your desired height */
	// 	box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
	// }
	// .slide__image {
	// 	.action-button {
	// 		position: absolute;
	// 		top: 30%;
	// 		left: 50%;
	// 		transform: translate(-50%, -50%);
	// 		padding: 15px 30px;
	// 		background-color: rgba(0, 0, 0, 0.4); /* white with 50% opacity */
	// 		color: #44d62c;
	// 		border: 1px solid gray;
	// 		text-decoration: none;
	// 		transition: background-color 0.3s ease, color 0.3s ease; /* transition effect */
			
	// 		/* hover effect */
	// 		&:hover {
	// 			background-color: rgba(0, 0, 0, 0.7); /* black with 50% opacity */
	// 			font-weight: bold;
	// 		}
	// 	}
	// }
	// .swiper-slide img {
	// 	max-height: 100%;
	// 	max-width: 100%;
	// 	object-fit: fit;
	// }
	// .swiper-button-next,
	// .swiper-button-prev {
	// 	background-color: rgba($white, 0.6);
	// 	color: $primary;
	// 	margin: 0;
	// 	transform: translateY(-50%);
	// 	transition: background-color 0.3s ease;
	// 	width: 50px;
	// 	height: 120px;
	// 	&::after {
	// 		font-size: 2rem;
	// 	}
	// 	&:hover {
	// 		background-color: $white;
	// 	}
	// }
	// .swiper-button-next {
	// 	right: 0;
	// }
	// .swiper-button-prev {
	// 	left: 0;
	// }
</style>

<template>
	<div class="dialog" style="background: white">
		<div
			class="dialog__header is-flex is-align-items-center is-justify-content-space-between p-4"
		>
			<h2>Creare cont client preferențial</h2>
			<button
				class="btn btn--close"
				@click="closeModal"
				title="Închide dialog"
				aria-label="Închide dialog"
			>
				<ion-icon name="close-outline"></ion-icon>
			</button>
		</div>
		<div class="dialog__content">
			<div class="inner avp-dialog-inner">
				<div class="content-panel p-4">
					<strong>AVANTAJE</strong><br>
					<ul class="preferred-customer-benefits">
						<li><ion-icon name="checkmark-outline" style="font-size: 24px; color: green; vertical-align: middle;"></ion-icon> Creare card de reducere de minim 5% la fiecare comandă în valoare de 50 euro + TVA</li>
						<li><ion-icon name="checkmark-outline" style="font-size: 24px; color: green; vertical-align: middle;"></ion-icon> Access direct la magazinele Forever din RO și străinătate</li>
						<li><ion-icon name="checkmark-outline" style="font-size: 24px; color: green; vertical-align: middle;"></ion-icon> Access gratuit la consultanță personalizată și informații de educație pentru un stil de viață sănătos</li>
						<li><ion-icon name="checkmark-outline" style="font-size: 24px; color: green; vertical-align: middle;"></ion-icon> Access la promoții speciale</li>
					</ul>
				</div>
				<div class="content-panel p-4 mt-4">
					<strong>ÎNSCRIE-TE AICI</strong><br>

					<form ref="form" class="form">
						<div class="columns is-multiline">
							<div class="column is-5-desktop is-11-tablet">
								<div
									class="field"
									:class="{ 'field--error': $v.name.$error }"
								>
									<label for="name">Nume și prenume</label>
									<input
										type="text"
										id="name"
										name="name"
										v-model="name"
									/>
									<p
										class="error"
										v-if="
											!$v.name.required && $v.name.$dirty
										"
									>
										Câmpul este obligatoriu
									</p>
									<p
										class="error"
										v-if="!$v.name.alpha && $v.name.$dirty"
									>
										Câmpul trebuie să conțină doar caractere
									</p>
								</div>
							</div>
							<div class="column is-5-desktop is-11-tablet">
								<div
									class="field"
									:class="{ 'field--error': $v.phone.$error }"
								>
									<label for="phone">Telefon</label>
									<input
										type="text"
										id="phone"
										name="phone"
										v-model="phone"
									/>
									<p
										class="error"
										v-if="
											!$v.phone.required &&
												$v.phone.$dirty
										"
									>
										Câmpul este obligatoriu
									</p>
									<p
										class="error"
										v-if="
											(!$v.phone.minLength ||
												!$v.phone.maxLength) &&
												$v.phone.$dirty
										"
									>
										Câmpul trebuie să conțină
										{{ $v.phone.$params.maxLength.max }}
										numere
									</p>
								</div>
							</div>
							<div class="column is-5-desktop is-11-tablet">
								<div
									class="field"
									:class="{ 'field--error': $v.mail.$error }"
								>
									<label for="mail">Email</label>
									<input
										type="text"
										id="mail"
										v-model="mail"
									/>
									<p
										class="error"
										v-if="
											!$v.mail.required && $v.mail.$dirty
										"
									>
										Câmpul este obligatoriu
									</p>
									<p
										class="error"
										v-if="!$v.mail.email && $v.mail.$dirty"
									>
										Adresa de mail trebuie să fie validă
									</p>
								</div>
							</div>
						
							<div class="column is-11">
								<div class="notification is-warning">
									După înregistrare, veți primi un email de confirmare și veți fi contactat în cel mai scurt timp pentru suport de către unul dintre consultanții noștri.
									<br>
									<strong>Meșter Marin Aurel – diamant manager</strong>
									<br>
									<a href="tel:+40744775567">
										<ion-icon name="call-outline"></ion-icon>
										+40 744 775 567
									</a>
									<br>
									<a href="mailto:contact@aloeveraproducts.ro">
										<ion-icon name="mail-outline"></ion-icon>
										contact@aloeveraproducts.ro
									</a>
								</div>
							</div>
						</div>
						<div class="field mb-0">
							<label
								for="terms"
								class="no-borders no-bg is-flex is-align-items-center check"
							>
								<div class="checkbox is-relative mr-3">
									<input
										type="checkbox"
										id="terms"
										v-model="terms"
									/>
									<span class="checkbox__box"
										><ion-icon
											name="checkmark-outline"
										></ion-icon
									></span>
								</div>
								<p>
									Sunt de acord cu prelucrarea datelor cu
									caracter personal conform Regulamentului
									nr.679/2016 și cu
									<a
										href="https://aloeveraproducts.ro/termeni-conditii.pdf"
										target="_blank"
										>politica de confidențialitate</a
									>
								</p>
							</label>
							<p
								class="error"
								v-if="!$v.terms.required && $v.terms.$dirty"
							>
								Câmpul este obligatoriu
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="dialog__footer is-flex is-justify-content-flex-end p-4">
			<button class="btn btn--secondary mr-2" @click="closeModal">
				Închidere
			</button>
			<button
				class="btn btn--primary"
				:disabled="$v.$anyError"
				@click.prevent="submit"
			>
				Creare cont
			</button>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import {
		required,
		minLength,
		maxLength,
		numeric,
		email,
	} from 'vuelidate/lib/validators';
	import { helpers } from 'vuelidate/lib/validators';
	const alpha = helpers.regex('alpha', /^([a-zA-Z]+\s)*[a-zA-Z]+$/);

	export default {
		props: [],
		validations: {
			name: {
				required,
				alpha,
			},
			phone: {
				required,
				numeric,
				minLength: minLength(10),
				maxLength: maxLength(10),
			},
			mail: {
				required,
				email,
			},
			terms: {
				required,
			}
		},
		data() {
			return {
				name: '',
				phone: '',
				mail: '',
				terms: null
			}
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			}
		},
		methods: {
			closeModal() {
				this.name = '';
				this.mail = '';
				this.phone = null;
				this.$v.$reset();

				this.$emit('close');
				this.$store.commit(
					'general/SET_MODAL_PREFERRED_CUSTOMER_STATUS',
					false
				);
			},
			submit() {
				// var self = this;
				this.$v.$touch();
				if (this.$v.$invalid) {
					return;
				} else {
					axios.post(
						`${process.env.VUE_APP_STRAPI_DATABASE_URL}/contacts`,
						{
							nume: this.name,
							mail: this.mail,
							telefon: parseInt(this.phone),
							politica_de_confidentialitate: this.terms
						}
					)
					.then((data) => {
						console.log('PREFERRED CUSTOMER SAVE response', data);
						// Create the click event in google analytics when the form is submitted and the response is 200
						if (data.status === 200) {
							// if(window.dataLayer) {
							// 	window.dataLayer.push({
							// 		event: 'click_purchase_product',
							// 	});
							// }
						}

						try {
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailc`,
								{
									mail: data.data.mail,
								}
							);
						
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailcd`,
								{ ...data }
							);
						}
						catch(err) {
							console.log('Probleme la trimitere emailuri de confirmare înregistrare.', err);
						}

						try {
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'submited',
									msg:
										'Mulțumim pentru interes. Înregistrarea a fost efectuată cu succes. Ați primit un email cu indicații pentru finalizarea procesului.',
								}
							);
							
							this.closeModal();
							this.$store.commit(
								'general/SET_MODAL_PREFERRED_CUSTOMER_STATUS',
								false
							);
						}
						catch (err) {
							console.log('Erori la procesare comandă.', err);
						}
						
					})
					.catch((err) => {
						console.log('Eroare generică la procesare înregistrare.', err);
						this.$store.commit(
							'general/SET_FORM_MSG_AFTER_SUBMITED',
							{
								status: 'error',
								msg:
									'Am întâmpinat o eroare. Înregistrarea nu a fost finalizată. Te rugăm să reîncerci sau să ne contactezi pe altă cale.',
							}
						);
						this.closeModal();
						this.$store.commit(
							'general/SET_MODAL_PREFERRED_CUSTOMER_STATUS',
							false
						);
					});
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.content-panel {
		background-color: $white;
		border-radius: $round-corners;
		margin-bottom: 0.5rem;
	}

	.avp-dialog-inner {
		overflow: auto;
		height: calc(100vh - 200px);	
	}

	// // Define a base class for the list
	// .preferred-customer-benefits {
	// 	// list-style: disc; // Set list item marker (optional)
	// 	margin: 0;
	// 	padding: 0;
	// }

	// // Style the list items
	// .preferred-customer-benefits li {
	// 	margin-bottom: 10px; // Add space between list items
		
	// 	&:before {
	// 		content: ""; // No need for Unicode 
	// 		display: inline-block; // Needed for proper positioning
	// 		font-family: 'Ionicons';
	// 		font-style: normal;
	// 		font-weight: normal; 
	// 		font-variant: normal;
	// 		text-transform: none;
	// 		line-height: 1;
	// 		-webkit-font-smoothing: antialiased;
	// 		-moz-osx-font-smoothing: grayscale;

	// 		// Use the selected icon name
	// 		content: "\f00c"; // Example: Assuming the icon code is for 'checkmark-outline'
	// 	}
	// }
	
</style>

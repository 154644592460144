import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Acasă',
	},
	{
		path: '/produse',
		name: 'Produse',
		component: () =>
			import(
				'../views/PageProducts.vue'
			),
	},
	{
		path: '/blog',
		name: 'Blog',
		component: () =>
			import(
				'../views/PageBlog.vue'
			),
	},
	{
		path: '/de-ce-noi',
		name: 'Despre',
		component: () =>
			import(
				'../views/PageAboutUs.vue'
			),
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () =>
			import(
				'../views/PageContact.vue'
			),
	},

	// 	path: '/sanatate',
	// 	name: 'Sănătate',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pageHealth" */ '../views/PageHealth.vue'
	// 		),
	// },
	// {
	// 	path: '/bauturi-nutritive',
	// 	name: 'Băuturi nutritive',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pageAloeVera" */ '../views/PageBauturiNutritive.vue'
	// 		),
	// },
	// {
	// 	path: '/produse-apicole',
	// 	name: 'Produse apicole',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pageAloeVera" */ '../views/PageProduseApicole.vue'
	// 		),
	// },
	// {
	// 	path: '/aloe-vera',
	// 	name: 'Aloe Vera',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pageAloeVera" */ '../views/PageAloeVera.vue'
	// 		),
	// },
	// {
	// 	path: '/sanatate',
	// 	name: 'Sănătate',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pageHealth" */ '../views/PageHealth.vue'
	// 		),
	// },
	// {
	// 	path: '/igiena-personala',
	// 	name: 'Igienă personală',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pagePersonalHygiene" */ '../views/PagePersonalHygiene.vue'
	// 		),
	// },
	// {
	// 	path: '/frumusete',
	// 	name: 'Frumusețe',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pageBeauty" */ '../views/PageBeauty.vue'
	// 		),
	// },
	// {
	// 	path: '/detoxifiere-controlul-greutatii',
	// 	name: 'Detoxifiere - controlul greutății',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pageWeightControl" */ '../views/PageWeightControl.vue'
	// 		),
	// },
	// {
	// 	path: '/nutritie-avansata-si-sport',
	// 	name: 'Nutriție avansată și sport',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pageSportAndNutrition" */ '../views/PageSportAndNutrition.vue'
	// 		),
	// },
	// {
	// 	path: '/dezvoltare-personala',
	// 	name: 'Dezvoltare personală',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pagePersonalDevelopment" */ '../views/PagePersonalDevelopment.vue'
	// 		),
	// },
	// {
	// 	path: '/business-alatura-te-echipei',
	// 	name: 'Business - alătură-te echipei',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "pageBusiness" */ '../views/PageBusiness.vue'
	// 		),
	// },
	{
		path: '/:categorie/',
		name: 'Categorie',
		pathToRegexOptions: { strict: true },
		// eslint-disable-next-line no-unused-vars
		beforeEnter: (to, from, next) => {
			document.titlu = to.params.categorie
			next()
		  },
		component: () =>
			import(
				/* webpackChunkName: "pageSubcategory" */ '../views/PageCategory.vue'
			),
	},
	{
		path: '/:categorie/:subcategorie/',
		name: 'Subcategorie',
		pathToRegexOptions: { strict: true },
		component: () =>
			import(
				/* webpackChunkName: "pageSubcategory" */ '../views/PageSubcategory.vue'
			),
	},
	{
		path: '/produse/produs/:produs',
		name: 'Produs',
		component: () =>
			import(
				/* webpackChunkName: "pageHomeProduct" */ '../views/PageProduct.vue'
			),
	},
	{
		path: '/:categorie/produse/:produs/',
		name: 'Categorie produs',
		pathToRegexOptions: { strict: true },
		component: () =>
			import(
				/* webpackChunkName: "pageCategoryproduct" */ '../views/PageProduct.vue'
			),
	},
	{
		path: '/:categorie/:subcategorie/produse/:produs/',
		name: 'Subcategorie produs',
		pathToRegexOptions: { strict: true },
		component: () =>
			import(
				/* webpackChunkName: "pageSubcategoryProduct" */ '../views/PageProduct.vue'
			),
	},
	{
		path: '/articole/articol/:articol',
		name: 'articol',
		component: () =>
			import(
				/* webpackChunkName: "pageHomeArticle" */ '../views/PageArticle.vue'
			),
	},
	{
		path: '/:categorie/articole/:articol/',
		name: 'Categorie articol',
		pathToRegexOptions: { strict: true },
		component: () =>
			import(
				/* webpackChunkName: "pageCategoryArticle" */ '../views/PageArticle.vue'
			),
	},
	{
		path: '/:categorie/:subcategorie/articole/:articol/',
		name: 'Subcategorie articol',
		pathToRegexOptions: { strict: true },
		component: () =>
			import(
				/* webpackChunkName: "pageSubcategoryArticle" */ '../views/PageArticle.vue'
			),
	},
	{
		path: '*',
		component: () =>
			import(/* webpackChunkName: "page404" */ '../views/Page404.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});



export default router;
